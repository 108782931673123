// @flow
import * as React from 'react'
import styled from 'react-emotion'
import { observer } from 'mobx-react'
import { action, extendObservable } from 'mobx'

type Props = {
  leftImage: string,
  rightImage: string
}

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 500px;
  margin-top: -2em;
  margin-bottom: 2em;
  background-image: ${props => `url(${props.src})`};
  background-position: left center;
  background-size: cover;
  @media screen and (max-width: 984px) {
    margin-left: 25px;
    margin-right: 25px;
    width: calc(100% - 50px);
    height: 440px;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
`

const Image = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-position: left center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  @media screen and (max-width: 984px) {
    height: 440px;
  }
  @media screen and (max-width: 700px) {
    height: 300px;
  }
`

const Bar = styled.div`
  position: absolute;
  height: 100%;
  width: 4px;
  left: 50%;
  background: white;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
`

const Handle = styled.div`
  position: absolute;
  top: 50%;
  left: 2px;
  transform: ${props =>
    props.hovered
      ? 'translate(-50%, -50%) scale(1.1)'
      : 'translate(-50%, -50%)'};
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: white;
  border: 1px solid #eee;
  box-shadow: ${props =>
    props.hovered ? '0 2px 10px rgba(0, 0, 0, 0.5)' : 'none'};
  transition: transform 0.2s ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
    transform: translate(-50%, -50%) scale(1.1);
  }
`

class Slider extends React.Component<Props> {
  wrapper: ?HTMLDivElement

  constructor() {
    super()
    extendObservable(this, { mouseX: 0, wrapperWidth: 0, mouseDown: false })
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWrapper)
    this.updateWrapper()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWrapper)
  }

  updateWrapper = action(() => {
    if (this.wrapper) {
      this.wrapperWidth = this.wrapper.getBoundingClientRect().width
      if (this.mouseX > 0)
        this.mouseX = Math.min(this.wrapperWidth / 2, this.mouseX)
    }
  })

  handleMouseDown = action(() => {
    this.mouseDown = true
  })

  handleMouseMove = action((ev: any) => {
    if (this.mouseDown && this.wrapper) {
      let pos = ev.pageX || ev.targetTouches[0].pageX
      let position = pos - this.wrapper.offsetLeft
      this.mouseX =
        Math.min(this.wrapperWidth, Math.max(position, 0)) -
        this.wrapperWidth / 2
    }
  })

  handleMouseUp = action(() => {
    this.mouseDown = false
  })

  render() {
    return (
      <ImageWrapper
        innerRef={wrapper => (this.wrapper = wrapper)}
        onMouseMove={this.handleMouseMove}
        onTouchMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        onTouchEnd={this.handleMouseUp}
        src={this.props.rightImage}
      >
        <Image
          style={{
            width: this.mouseX + this.wrapperWidth / 2
          }}
          src={this.props.leftImage}
        />
        <Bar style={{ transform: `translateX(${this.mouseX}px)` }}>
          <Handle
            hovered={this.mouseDown}
            onTouchStart={this.handleMouseDown}
            onMouseDown={this.handleMouseDown}
          />
        </Bar>
      </ImageWrapper>
    )
  }
}

export default observer(Slider)
