// @flow
import * as React from 'react'
import { graphql } from 'gatsby'

import { Content, Section, Meta } from '../components/Theme'
import Header from '../components/Header'
import Slider from '../components/Slider'
import Layout from '../components/Layout'

import type { Props } from './project'

export default (props: Props) => {
  const { project } = props.data
  const splitHTML = project.body
    ? project.body.markdown.html.split('<p>@@react@@</p>')
    : ''
  const thumbnail =
    project.images && project.images[0] && project.images[0].resize.src
  return (
    <Layout location={props.location}>
      <div>
        <Meta title={project.title} image={thumbnail} />
        <Header
          title={project.title}
          introduction={
            project.introduction && project.introduction.introduction
          }
        />
        <Content>
          <Section dangerouslySetInnerHTML={{ __html: splitHTML[0] }} />
          <Slider
            leftImage="/joep/originalframe.jpg"
            rightImage="/joep/neuralframe.jpg"
          />
          <Section dangerouslySetInnerHTML={{ __html: splitHTML[1] }} />
        </Content>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query JoepQuery($slug: String!) {
    project: contentfulProject(slug: { eq: $slug }) {
      title
      slug
      introduction {
        introduction
      }
      images {
        resize(width: 900, height: 500, cropFocus: LEFT, quality: 85) {
          src
        }
      }
      body {
        markdown: childMarkdownRemark {
          html
        }
      }
    }
  }
`
